import axios from "axios";
import * as apiLinks from "./apiLinks";
import store from "../store";

function getAPIConfigurationSecured() {
    const token = (store.getState() as any).currentUser.token;
    return axios.create({
        timeout: 1000000,
        responseType: "json",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        }
    });
}

function getAPIConfigurationSecuredMultipart() {
    const token = (store.getState() as any).currentUser.token;
    return axios.create({
        timeout: 1000000,
        responseType: "json",
        headers: {
            'Content-Type': 'multipart/form-data: boundary=add-random-characters',
            'Pragma': 'no-cache',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        }
    });
}

function getAPIConfigurationUnsecured() {
    return axios.create({
        timeout: 10000,
        responseType: "json",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    });
}

// Events

export function getEvents() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationUnsecured();
        return apiClient({
            url: apiLinks.EVENTS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

// Contacts

export function getContacts() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.CONTACTS + "/List",
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteContact(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.CONTACTS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editContact(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.CONTACTS + "/" + values.idContact, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getContact(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.CONTACTS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addContact(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.CONTACTS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}
// Groups

export function getGroups() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.GROUPS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteGroup(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.GROUPS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editGroup(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.GROUPS + "/" + values.idGroup, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getGroup(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.GROUPS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addGroup(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.GROUPS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Appointments

export function getAppointmentsOfGroup(group : string) {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.APPOINTMENTS + "/" + group  + "/list",
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function getResponsesOfAppointment(appointment : string) {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.APPOINTMENTS + "/" + appointment  + "/Responses",
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function addAppointment(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.APPOINTMENTS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function deleteAppointment(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.APPOINTMENTS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editAppointment(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.APPOINTMENTS + "/" + values.idAppointment, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getAppointment(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.APPOINTMENTS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

// Users

export function getUsers() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.USERS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteUser(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.USERS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editUser(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.USERS + "/" + values.idUser, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getUser(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.USERS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addUser(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.USERS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function login(user : any) {
    return new Promise((resolve, reject) => {
        let apiClient = getAPIConfigurationUnsecured();
        return apiClient.post(apiLinks.USERS + "/Login", user)
            .then(function (response : any) {
                if (response.data.statusCode === 200 && response.data.data.token !== "") {
                    resolve(response.data.data);
                } else {
                    reject("Kombination von Benutzer und Passwort nicht gefunden.");
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Kool

export function getKoolEvents() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.KOOL + "/events",
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function getKoolReservations() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.KOOL + "/reservations",
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function addReservation(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.KOOL + "/reservations", values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Expenses

export function getExpenses() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.EXPENSES,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function addExpense(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecuredMultipart();
        return apiClient.post(apiLinks.EXPENSES, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}