import * as types from "../actions/actionTypes";
import {IAction} from "../interfaces/actions";

interface AppointmentReducerState {
    state: string,
    selectedObject: object,
    errorMessage: string,
    data: any[],
    responses: any[],
    selectedGroup: string,
}

const defaultState = {
    data: [],
    responses: [],
    selectedObject: {},
    state: "success",
    errorMessage: "",
    selectedGroup: ""
};

const appointmentReducer = (state : AppointmentReducerState = defaultState, action: IAction) => {
    switch (action.type) {
        case types.SET_APPOINTMENTS_CURRENT_STATE:
            return {...state, state: action.data};
        case types.SET_APPOINTMENTS_ERROR:
            return {...state, errorMessage: action.data, data: [], state: "error"};
        case types.SET_APPOINTMENTS_SELECTED_OBJECT:
            return {...state, selectedObject : action.data};
        case types.SET_APPOINTMENTS_FILTER:
            return {...state, selectedGroup: action.data};
        case types.SET_APPOINTMENTS:
            return Object.assign({}, state, {
                data: action.data,
                responses: [],
                state: "success"
            });
        case types.SET_APPOINTMENTS_RESPONSES:
            return Object.assign({}, state, {
                responses: action.data,
                state: "responses"
            });
        default:
            return state;
    }
};

export default appointmentReducer;
