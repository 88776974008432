import * as types from "../actions/actionTypes";
import {IAction} from "../interfaces/actions";

interface KoolReducerState {
    state: string,
    selectedObject: object,
    errorMessage: string,
    events: any[],
    reservations: any[],
}

const defaultState = {
    events: [],
    reservations: [],
    selectedObject: {},
    state: "success",
    errorMessage: "",
};

const koolReducer = (state : KoolReducerState = defaultState, action: IAction) => {
    switch (action.type) {
        case types.SET_KOOL_CURRENT_STATE:
            return {...state, state: action.data};
        case types.SET_KOOL_ERROR:
            return {...state, errorMessage: action.data, data: [], reservations: [], state: "error"};
        case types.SET_KOOL_SELECTED_OBJECT:
            return {...state, selectedObject : action.data};
        case types.SET_KOOL_FILTER:
            return {...state, selectedGroup: action.data};
        case types.SET_KOOL_EVENTS:
            return Object.assign({}, state, {
                events: action.data,
                reservations: [],
                state: "success"
            });
        case types.SET_KOOL_RESERVATIONS:
            return Object.assign({}, state, {
                reservations: action.data,
                state: "reservations"
            });
        default:
            return state;
    }
};

export default koolReducer;
