import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as contactActions from "../../actions/contactActions";
import * as Yup from "yup";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import FormError from "../shared/forms/FormError";
import {IFormProps} from "../../interfaces/interfaces";
import {Button, Form as FormStyled} from "semantic-ui-react";

class EditContact extends Component<IFormProps, EditContactFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            type: 0,
            street: "",
            city: "",
            firstName: "",
            idContact: "",
            lastName: "",
            mail: "",
            phone: "",
            zip: "",
            vulgo: ""

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    validationSchemaData = Yup.object().shape({
        firstName : Yup.string().required("* Feld ist erforderlich"),
        lastName : Yup.string().required("* Feld ist erforderlich"),
        street : Yup.string().required("* Feld ist erforderlich"),
        zip : Yup.string().required("* Feld ist erforderlich"),
        city : Yup.string().required("* Feld ist erforderlich"),
        mail : Yup.string().required("* Feld ist erforderlich"),
        idContact : Yup.string().required("* Feld ist erforderlich"),
        type : Yup.number().required("* Feld ist erforderlich"),
        phone : Yup.string(),
        vulgo : Yup.string()
    });

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    onCancel(): void {
        store.dispatch(contactActions.backToList());
    }

    handleSubmit(values : EditContactFormValues, actions : FormikHelpers<EditContactFormValues>) : void {
        store.dispatch(contactActions.editContact(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Kontakt bearbeiten"/>
                <h2>Kontakt bearbeiten</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idContact">Kontakt ID</label>
                                <Field type="hidden" name="idContact"/>
                                <p>{this.state.idContact}</p>
                                <FormError name="idContact" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="firstName">Vorname</label>
                                <Field type="text" name="firstName" placeholder="Hans"/>
                                <FormError name="firstName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="lastName">Nachname</label>
                                <Field type="text" name="lastName" placeholder="Muster"/>
                                <FormError name="lastName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="vulgo">Ceviname</label>
                                <Field type="text" name="vulgo" placeholder="Pfoschte"/>
                                <FormError name="vulgo" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="street">Strasse</label>
                                <Field type="text" name="street" placeholder="Musterstrasse 11"/>
                                <FormError name="street" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="zip">PLZ</label>
                                <Field type="text" name="zip" placeholder="1234"/>
                                <FormError name="zip" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="city">Ort</label>
                                <Field type="text" name="city" placeholder="Musterhausen"/>
                                <FormError name="city" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="phone">Telefonnummer</label>
                                <Field type="text" name="phone" placeholder="012 345 56 78"/>
                                <FormError name="phone" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mail">Mail</label>
                                <Field type="text" name="mail" placeholder="mail@test.ch"/>
                                <FormError name="mail" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="type">Typ (1=Jungschar, 2=Fröschli und 3=Verein)</label>
                                <Field type="number" name="type" placeholder="3"/>
                                <FormError name="type" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Kontakt speichern</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default EditContact;