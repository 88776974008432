import {connect} from "react-redux";
import Appointments from "../components/appointments/Appointments";

function mapStateToProps(store : any) {
    return {
        store  : store.appointments,
        appointments : store.appointments.data,
        groups : store.groups.data,
        responses : store.appointments.responses
    }
}

const AppointmentsContainer = connect(mapStateToProps)(Appointments);

export default AppointmentsContainer;