import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as groupActions from "../../actions/groupActions";
import TitleHelper from "../shared/TitleHelper";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";

class DeleteGroup extends Component<IFormProps, DeleteGroupFormValues> {
    constructor(props : any){
        super(props);
        this.state = {
            idGroup: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }
    onCancel(): void {
        store.dispatch(groupActions.backToList());
    }

    handleSubmit(values : DeleteGroupFormValues, actions : FormikHelpers<DeleteGroupFormValues>) : void {
        store.dispatch(groupActions.deleteGroup(values.idGroup));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Cevigruppe löschen"/>
                <h2>Cevigruppe löschen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idGroup">Gruppen ID</label>
                                <Field type="hidden" name="idGroup"/>
                                <p>{this.state.idGroup}</p>
                                <FormError name="idGroup" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Cevigruppe löschen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default DeleteGroup;