import React, {Component} from "react";
import * as appointmentActions from "../../actions/appointmentActions";
import TitleHelper from "../shared/TitleHelper";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import {Button} from "semantic-ui-react";
import DataTable from "../shared/DataTable";
import moment from "moment";

class AppointmentResponses extends Component<IFormProps, any> {
    constructor(props : any){
        super(props);
        this.state = {
            responses: []
        };
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.listValues !== undefined) {
            this.setState({responses: this.props.listValues});
        }
    }
    onCancel(): void {
        console.log("on Cancel")
        store.dispatch(appointmentActions.backToList());
    }

    addActions(items : any) : void {
        items.map((element : any) => {
            element.typeString = element.type === 1 ? "Anmeldung" : "Abmeldung";
        });
    }

    render(): React.ReactNode {
        let headings = [
            {"name" : "Typ", "sortkey" : "typeString"},
            {"name" : "Name", "sortkey" : "name"},
            {"name" : "Nachricht", "sortkey" : "message"}
        ];
        this.addActions(this.state.responses);
        return (
            <>
                <div className="form-wrapper">
                    <TitleHelper title="An- und Abmeldungen"/>
                    <h2>An- und Abmeldungen</h2>
                </div>
                <DataTable headings={headings} rows={this.state.responses} onSort={null} emptyText="Keine Antworten" />
                <br/><br/>
                <Button className="first warning" onClick={this.onCancel} type="button">Zur Liste zurück</Button>
            </>
        );
    }
}

export default AppointmentResponses;