import React, {Component} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as expenseActions from "../../actions/expenseActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";
import AddExpense from "./AddExpense";
import AboutExpenses from "./AboutExpenses";

class Expenses extends Component<any, any> {
    constructor(props : any){
        super(props);
    }

    componentDidMount() {
        store.dispatch(expenseActions.backToList());
    }

    addExpense(): void {
        store.dispatch(expenseActions.addExpenseForm());
    }

    render() {
        const {store, expenses} = this.props;
        return(
            <>
                <Header as="h1" className="mainHeader">Spesen</Header>
                <div className="button-group">
                    {isEmpty(store.selectedObject) && store.state !== "add" &&
                        <>
                            <Button onClick={this.addExpense} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Spesen hinzufügen</Button>
                        </>
                    }
                </div>
                <FormMessageContainer/>
                {(() => {
                    console.error(store.state);
                    switch (store.state) {
                        case "add":
                            return <AddExpense/>;
                        case "success":
                            return <AboutExpenses/>;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Expenses;