import {connect} from "react-redux";
import Users from "../components/users/Users";

function mapStateToProps(store : any) {
    return {
        store  : store.users,
        users : store.users.data
    }
}

const UsersContainer = connect(mapStateToProps)(Users);

export default UsersContainer;