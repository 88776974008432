export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENTS_ERROR = "SET_EVENTS_ERROR";
export const SET_EVENTS_CURRENT_STATE = "SET_EVENTS_CURRENT_STATE";
export const SET_EVENTS_SELECTED_OBJECT = "SET_EVENTS_SELECTED_OBJECT";

export const SET_APPOINTMENTS = "SET_APPOINTMENTS";
export const SET_APPOINTMENTS_ERROR = "SET_APPOINTMENTS_ERROR";
export const SET_APPOINTMENTS_CURRENT_STATE = "SET_APPOINTMENTS_CURRENT_STATE";
export const SET_APPOINTMENTS_SELECTED_OBJECT = "SET_APPOINTMENTS_SELECTED_OBJECT";
export const SET_APPOINTMENTS_FILTER = "SET_APPOINTMENTS_FILTER";
export const SET_APPOINTMENTS_RESPONSES = "SET_APPOINTMENTS_RESPONSES";

export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACTS_ERROR = "SET_CONTACTS_ERROR";
export const SET_CONTACTS_CURRENT_STATE = "SET_CONTACTS_CURRENT_STATE";
export const SET_CONTACTS_SELECTED_OBJECT = "SET_CONTACTS_SELECTED_OBJECT";

export const SET_GROUPS = "SET_GROUPS";
export const SET_GROUPS_ERROR = "SET_GROUPS_ERROR";
export const SET_GROUPS_CURRENT_STATE = "SET_GROUPS_CURRENT_STATE";
export const SET_GROUPS_SELECTED_OBJECT = "SET_GROUPS_SELECTED_OBJECT";

export const SET_USERS = "SET_USERS";
export const SET_USERS_ERROR = "SET_USERS_ERROR";
export const SET_USERS_CURRENT_STATE = "SET_USERS_CURRENT_STATE";
export const SET_USERS_SELECTED_OBJECT = "SET_USERS_SELECTED_OBJECT";

export const SET_FORM_PROCESSING = "SET_FORM_PROCESSING";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_FORM_SUCCESS = "SET_FORM_SUCCESS";
export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";
export const CLEAR_FORM_SUCCESS = "CLEAR_FORM_SUCCESS";
export const SET_FORM_TEMP_OBJECT = "SET_FORM_TEMP_OBJECT";
export const CLEAR_FORM_TEMP_OBJECT = "CLEAR_FORM_TEMP_OBJECT";

export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";

export const SET_KOOL_EVENTS = "SET_KOOL_EVENTS";
export const SET_KOOL_ERROR = "SET_KOOL_ERROR";
export const SET_KOOL_CURRENT_STATE = "SET_KOOL_CURRENT_STATE";
export const SET_KOOL_SELECTED_OBJECT = "SET_KOOL_SELECTED_OBJECT";
export const SET_KOOL_FILTER = "SET_KOOL_FILTER";
export const SET_KOOL_RESERVATIONS = "SET_KOOL_RESERVATIONS";

export const SET_EXPENSES = "SET_EXPENSES";
export const SET_EXPENSES_ERROR = "SET_EXPENSES_ERROR";
export const SET_EXPENSES_CURRENT_STATE = "SET_EXPENSES_CURRENT_STATE";
export const SET_EXPENSES_SELECTED_OBJECT = "SET_EXPENSES_SELECTED_OBJECT";
export const SET_EXPENSES_FILTER = "SET_EXPENSES_FILTER";
