import {connect} from "react-redux";
import Contacts from "../components/contacts/Contacts";

function mapStateToProps(store : any) {
    return {
        store  : store.contacts,
        contacts : store.contacts.data
    }
}

const ContactsContainer = connect(mapStateToProps)(Contacts);

export default ContactsContainer;