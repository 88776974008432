import {connect} from "react-redux";
import Groups from "../components/groups/Groups";

function mapStateToProps(store : any) {
    return {
        store  : store.groups,
        groups : store.groups.data
    }
}

const GroupsContainer = connect(mapStateToProps)(Groups);

export default GroupsContainer;