import React, {Component} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as koolActions from "../../actions/koolActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import AddReservation from "./AddReservation";

class Reservations extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.update = this.update.bind(this);
        this.initialData = this.initialData.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.renderEventContent = this.renderEventContent.bind(this);
    }
    componentDidMount(): void {
        this.initialData();
        this.update();
    }

    update(): void {
        store.dispatch(koolActions.getEvents());
    }

    initialData(): void {
        this.update();
    }

    onFilterChanged(event : any) : void {
        let filter = event.target.value;
        store.dispatch(koolActions.onFilterChanged(filter));
    }

    addReservation(): void {
        store.dispatch(koolActions.addReservationForm());
    }

    renderEventContent(eventInfo : any) {
        return (
            <div className="cal-item-box">
                <span className="cal-item-box__title">{eventInfo.event.title}</span>
                <span className="cal-item-box__time">{eventInfo.timeText}</span>
                <span className="cal-item-box__rooms">{eventInfo.event.extendedProps.rooms.join(", ")}</span>
            </div>
        )
    }

    renderEvents(items : any) : void {
        items.map((element : any) => {
            if (element.type !== 2) {
                element.title = "NICHT SICHTBAR (KIRCHE)";
            }
        });
    }

    render() {
        const {store, events} = this.props;
        this.renderEvents(events);
        return(
            <>
                <Header as="h1" className="mainHeader">Reservationen</Header>
                <div className="button-group">
                    {isEmpty(store.selectedObject) && store.state !== "add" &&
                        <>
                            <Button onClick={this.addReservation} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Reservation hinzufügen</Button>
                            <Button onClick={this.update} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'sync']} />Liste neuladen</Button>
                        </>
                    }
                </div>
                <FormMessageContainer/>
                {(() => {
                    switch (store.state) {
                        case "add":
                            return <AddReservation/>;
                        case "success":
                            return <FullCalendar
                                plugins={[ dayGridPlugin ]}
                                events={events}
                                locale="de"
                                displayEventEnd={true}
                                initialView="dayGridWeek"
                                firstDay={1}
                                eventContent={this.renderEventContent}
                            />;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Reservations;