import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as groupActions from "../../actions/groupActions";
import * as Yup from "yup";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import FormError from "../shared/forms/FormError";
import {IFormProps} from "../../interfaces/interfaces";
import {Button, Form as FormStyled} from "semantic-ui-react";

class EditGroup extends Component<IFormProps, EditGroupFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            mailLeaders: "", numberNotification: "",
            description: "", idGroup: "", leaders: "", mail: "", name: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    validationSchemaData = Yup.object().shape({
        name : Yup.string().required("* Feld ist erforderlich"),
        mail : Yup.string().required("* Feld ist erforderlich").email("Muss eine Mailadresse sein.").nullable(),
        mailLeaders : Yup.string().required("* Feld ist erforderlich").email("Muss eine Mailadresse sein.").nullable(),
        leaders : Yup.string().required("* Feld ist erforderlich"),
        numberNotification : Yup.string().nullable(),
        description : Yup.string().nullable()
    });

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    onCancel(): void {
        store.dispatch(groupActions.backToList());
    }

    handleSubmit(values : EditGroupFormValues, actions : FormikHelpers<EditGroupFormValues>) : void {
        store.dispatch(groupActions.editGroup(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Cevigruppe bearbeiten"/>
                <h2>Cevigruppe bearbeiten</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idGroup">Gruppen ID</label>
                                <Field type="hidden" name="idGroup"/>
                                <p>{this.state.idGroup}</p>
                                <FormError name="idGroup" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="name">Name</label>
                                <Field type="text" name="name" placeholder="Sparrows"/>
                                <FormError name="name" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="leaders">Leiter</label>
                                <Field type="text" name="leaders" placeholder="Maxli"/>
                                <FormError name="leaders" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="description">Beschreibung</label>
                                <Field type="text" name="description" placeholder="Text"/>
                                <FormError name="description" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mail">Mailverteiler Kinder</label>
                                <Field type="text" name="mail" placeholder="sparrows@ceviduernten.ch"/>
                                <FormError name="mail" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mailLeaders">Mailverteiler Leiter</label>
                                <Field type="text" name="mailLeaders" placeholder="sparrows.leiter@ceviduernten.ch"/>
                                <FormError name="mailLeaders" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="numberNotification">Nummer für Benachrichtigungen</label>
                                <Field type="text" name="numberNotification" placeholder="079 323 56 62"/>
                                <FormError name="numberNotification" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Cevigruppe speichern</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default EditGroup;