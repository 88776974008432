import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as appointmentActions from "../../actions/appointmentActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";
import {DateTimePicker} from "react-widgets";


class AddAppointment extends Component<IFormProps, CreateAppointmentFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            groupName: "",
            date: new Date(),
            groupId: "",
            infos: "",
            location: "",
            time: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        time : Yup.string().required("* Feld ist erforderlich"),
        infos : Yup.string().required("* Feld ist erforderlich"),
        location : Yup.string().required("* Feld ist erforderlich"),
        date : Yup.date().nullable(true)
    });

    onCancel(): void {
        store.dispatch(appointmentActions.backToList());
    }

    handleSubmit(values : CreateAppointmentFormValues, actions : FormikHelpers<CreateAppointmentFormValues>) : void {
        store.dispatch(appointmentActions.addAppointment(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Chästlizetteleintrag hinzufügen"/>
                <h2>Chästlizetteleintrag hinzufügen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="groupName">Gruppe</label>
                                <Field type="text" disabled={true} name="groupName"/>
                                <FormError name="groupName" />
                            </FormStyled.Field>
                            <Field type="text" hidden name="groupId"/>
                            <FormError name="groupId" />
                            <FormStyled.Field>
                                <label htmlFor="date">Datum</label>
                                <Field>
                                    {(bag : any) => (
                                        <DateTimePicker containerClassName="datetimepicker" value={bag.form.values.date} format={'DD.MM.YYYY'} time={false} onChange={value  => bag.form.setFieldValue("date", value)} />
                                    )}
                                </Field>
                                <FormError name="date" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="time">Zeit</label>
                                <Field type="text" name="time" placeholder="14.00 bis 17.00 Uhr"/>
                                <FormError name="time" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="location">Ort</label>
                                <Field type="text" name="location" placeholder="Pfarrhaus Dürnten"/>
                                <FormError name="location" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="infos">Informationen</label>
                                <Field component="textarea" name="infos" placeholder="Hier kommt der Text"/>
                                <FormError name="infos" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Chästlizetteleintrag hinzufügen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddAppointment;