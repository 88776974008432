import React, {Component} from "react";
import {IGroupSelectListProps} from "../../interfaces/interfaces";
import SelectList from "./SelectList";

class GroupSelectList extends Component<IGroupSelectListProps, any> {
    render() {
        let properties = ["idGroup", "name"];
        return (
            <SelectList options={this.props.options} class="customer-list" onChange={this.props.onChange} selectedValue={this.props.selectedValue} properties={properties} defaultText="bitte Gruppe auswählen" />
        );
    };
}

export default GroupSelectList;