import {connect} from "react-redux";
import Expenses from "../components/expenses/Expenses";

function mapStateToProps(store : any) {
    return {
        store  : store.expenses,
        expenses : store.expenses.data,
    }
}

const ExpensesContainer = connect(mapStateToProps)(Expenses);

export default ExpensesContainer;