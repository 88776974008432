import React, {Component, Fragment} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as groupActions from "../../actions/groupActions"
import * as appointmentActions from "../../actions/appointmentActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTable from "../shared/DataTable";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";
import GroupSelectList from "../shared/GroupSelectList";
import AddAppointment from "./AddAppointment";
import moment from "moment";
import DeleteAppointment from "./DeleteAppointment";
import EditAppointment from "./EditAppointment";
import AppointmentResponses from "./AppointmentResponses";

class Appointments extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            isCustomerSelected: true
        };
        this.update = this.update.bind(this);
        this.initialData = this.initialData.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
    }
    componentDidMount(): void {
        this.initialData();
        this.update();
    }

    update(): void {
        if ((store.getState() as any).appointments.selectedGroup === undefined || (store.getState() as any).appointments.selectedGroup === null || (store.getState() as any).appointments.selectedGroup === "") {
            this.setState({'isCustomerSelected' : false});
        } else {
            // TODO
            store.dispatch(appointmentActions.getAppointmentsOfGroup());
        }
    }

    initialData(): void {
        store.dispatch(groupActions.getGroups());
    }

    addAppointment(): void {
        store.dispatch(appointmentActions.addAppointmentForm());
    }

    deleteAppointment(id : string) : void {
        store.dispatch(appointmentActions.getAppointmentAndCallAction(id, "delete"));
    }

    editAppointment(id : string) : void {
        store.dispatch(appointmentActions.getAppointmentAndCallAction(id, "edit"));
    }

    getResponses(id : string) : void {
        store.dispatch(appointmentActions.getResponsesOfAppointment(id));
    }

    onFilterChanged(event : any) : void {
        let filter = event.target.value;
        store.dispatch(appointmentActions.onFilterChanged(filter));
        this.setState({'isCustomerSelected' : filter !== ""});
    }

    renderActions(element : any) {
        return (
            <Fragment>
                <a href="#" onClick={() => this.deleteAppointment(element.idAppointment)}><FontAwesomeIcon icon={['fas', 'trash']} /> Löschen</a>
                <a href="#" onClick={() => this.editAppointment(element.idAppointment)}><FontAwesomeIcon icon={['fas', 'edit']} /> Bearbeiten</a>
                <a href="#" onClick={() => this.getResponses(element.idAppointment)}><FontAwesomeIcon icon={['fas', 'edit']} /> An- und Abmeldungen</a>
            </Fragment>
        )
    }

    addActions(items : any) : void {
        items.map((element : any) => {
            element.action = this.renderActions(element);
            element.dateString = moment(element.date).format("DD.MM.YYYY");
        });
    }

    render() {
        const {store, appointments, groups, responses} = this.props;
        this.addActions(appointments);
        let headings = [
            {"name" : "ID", "sortkey" : "idAppointment"},
            {"name" : "Datum", "sortkey" : "dateString"},
            {"name" : "Zeit", "sortkey" : "time"},
            {"name" : "Aktionen", "sortkey" : "action"}
        ];
        return(
            <>
                <Header as="h1" className="mainHeader">Chästlizettel</Header>
                <div className="button-group">
                    {isEmpty(store.selectedObject) && this.state.isCustomerSelected && store.state !== "add" &&
                        <>
                            <Button onClick={this.addAppointment} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Chästlizetteleintrag hinzufügen</Button>
                            <Button onClick={this.update} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'sync']} />Liste neuladen</Button>
                        </>
                    }
                    {isEmpty(store.selectedObject)  && store.state !== "add" &&
                        <GroupSelectList options={groups} onChange={this.onFilterChanged} selectedValue={store.selectedGroup} />
                    }
                </div>
                <FormMessageContainer/>
                {(() => {
                    if (!this.state.isCustomerSelected && store.state !== "add") {
                        return null;
                    }
                    switch (store.state) {
                        case "success":
                            return <DataTable headings={headings} rows={appointments} onSort={null} />;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        case "add":
                            return <AddAppointment initalValues={store.selectedObject}/>;
                        case "delete":
                            return <DeleteAppointment initalValues={store.selectedObject}/>;
                        case "responses":
                            return <AppointmentResponses listValues={responses}/>;
                        case "edit":
                            return <EditAppointment initalValues={store.selectedObject}/>;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Appointments;