import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as userActions from "../../actions/userActions";
import * as Yup from "yup";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import FormError from "../shared/forms/FormError";
import {IFormProps} from "../../interfaces/interfaces";
import {Button, Form as FormStyled} from "semantic-ui-react";
import SimpleSelectListForm from "../shared/forms/SimpleSelectListForm";
import {getRoleList} from "../../helpers/SelectListHelpers";

class EditUser extends Component<IFormProps, EditUserFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            fullName: "", idUser: "", loginName: "", mail: "", password: "", role: 0, vulgo: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    validationSchemaData = Yup.object().shape({
        fullName : Yup.string().required("* Feld ist erforderlich"),
        loginName : Yup.string().required("* Feld ist erforderlich"),
        vulgo : Yup.string().required("* Feld ist erforderlich"),
        mail : Yup.string().required("* Feld ist erforderlich"),
        role : Yup.number().required("* Feld ist erforderlich"),
        idUser : Yup.string().required("* Feld ist erforderlich")
    });

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    onCancel(): void {
        store.dispatch(userActions.backToList());
    }

    handleSubmit(values : EditUserFormValues, actions : FormikHelpers<EditUserFormValues>) : void {
        store.dispatch(userActions.editUser(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Benutzer bearbeiten"/>
                <h2>Benutzer bearbeiten</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idUser">Benutzer ID</label>
                                <Field type="hidden" name="idUser"/>
                                <p>{this.state.idUser}</p>
                                <FormError name="idUser" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="loginName">Loginname</label>
                                <Field type="text" name="loginName" placeholder="ceviname"/>
                                <FormError name="loginName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="fullName">Vorname und Nachname</label>
                                <Field type="text" name="fullName" placeholder="Hans Muster"/>
                                <FormError name="fullName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="vulgo">Ceviname</label>
                                <Field type="text" name="vulgo" placeholder="Pfoschte"/>
                                <FormError name="vulgo" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mail">Mail</label>
                                <Field type="text" name="mail" placeholder="mail@test.ch"/>
                                <FormError name="mail" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="role">Rolle</label>
                                <SimpleSelectListForm options={getRoleList()} selectName="role" selectedValue={this.state.role} defaultText="-- Rolle auswählen --" />
                                <FormError name="role" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Benutzer speichern</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default EditUser;