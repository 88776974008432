import React, {Component} from "react";
import packageJson from '../../../package.json';

class Copyright extends Component {
    render(): React.ReactNode {
        return (
            <div className="copyright">
                <p>(c) by Cevi Dürnten 2022, Version {packageJson.version} | {window.location.host}</p>
            </div>
        );
    }
}

export default Copyright;