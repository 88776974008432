import {connect} from "react-redux";
import Reservations from "../components/kool/Reservations";

function mapStateToProps(store : any) {
    return {
        store  : store.kool,
        reservations : store.kool.reservations,
        events : store.kool.events,
    }
}

const KoolContainer = connect(mapStateToProps)(Reservations);

export default KoolContainer;