import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import store from "../store";
import moment from "moment";

function receiveAppointments(data : any) {
    return {
        type : types.SET_APPOINTMENTS,
        data : data
    }
}

function setAppointmentsResponses(data : any) {
    return {
        type : types.SET_APPOINTMENTS_RESPONSES,
        data : data
    }
}

function receiveAppointmentsError(errorMessage : string) {
    return {
        type : types.SET_APPOINTMENTS_ERROR,
        data : errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_APPOINTMENTS_CURRENT_STATE,
        data : state
    }
}

function changeFilteringOfData(data : any) {
    return {
        type : types.SET_APPOINTMENTS_FILTER,
        data : data
    }
}

function setSelectedObject(appointment : object) {
    return {
        type : types.SET_APPOINTMENTS_SELECTED_OBJECT,
        data : appointment
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(setSelectedObject({}));
        dispatch(setAppointmentsResponses([]));
        dispatch(changeState("success"));
    }
}

export function addAppointmentForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
        let object : any = {};
        object.groupId = (store.getState() as any).appointments.selectedGroup;
        let group = (store.getState() as any).groups.data.filter((x : any) => x.idGroup === object.groupId);
        object.groupName = group[0].name;
        dispatch(setSelectedObject(object));
    }
}

export function getAppointmentAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getAppointmentFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

function getAppointmentFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getAppointment(id).then(function (res) {
            res.date = new Date(res.date);
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveAppointmentsError(error));
            reject();
        });
    });
}

export function onFilterChanged(filter : any): any {
    return function (dispatch : any) {
        dispatch(changeFilteringOfData(filter));
        if (filter !== "") {
            getAppointmentsOfGroupFromAPI(dispatch, filter);
        }
    }
}

function getAppointmentsOfGroupFromAPI(dispatch : any, group : string) : any {
    dispatch(changeState("loading"));
    api.getAppointmentsOfGroup(group).then(function (res) {
        dispatch(receiveAppointments(res));
    }).catch(function (error) {
        dispatch(receiveAppointmentsError(error));
    });
}

function getResponsesOfAppointmentFromAPI(dispatch : any, appointment : string) : any {
    dispatch(changeState("loading"));
    api.getResponsesOfAppointment(appointment).then(function (res) {
        dispatch(setAppointmentsResponses(res));
    }).catch(function (error) {
        dispatch(receiveAppointmentsError(error));
    });
}

export function getAppointmentsOfGroup() : any {
    return function (dispatch : any) {
        let group = (store.getState() as any).appointments.selectedGroup;
        getAppointmentsOfGroupFromAPI(dispatch, group);
    }
}

export function getResponsesOfAppointment(appointment : string) : any {
    return function (dispatch : any) {
        getResponsesOfAppointmentFromAPI(dispatch, appointment);
    }
}

export function addAppointment(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        let data = JSON.parse(JSON.stringify(values));
        if (values.date === "" ||  values.date === " " || values.date === null || values.date === undefined) {
            data.date = "";
        } else {
            data.date = moment(values.date).format('YYYY-MM-DD');
        }
        delete data.groupName;
        api.addAppointment(data).then(function (res : any) {
            dispatch(forms.setFormSuccess("Der Chästlizetteleintrag wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            dispatch(setSelectedObject({}));
            getAppointmentsOfGroupFromAPI(dispatch, values.groupId);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function deleteAppointment(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteAppointment(id).then(function (res) {
            dispatch(forms.setFormSuccess("Der Chästlizetteleintrag wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(setSelectedObject({}));
            let group = (store.getState() as any).appointments.selectedGroup;
            getAppointmentsOfGroupFromAPI(dispatch, group);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editAppointment(values : any): any {
    return function (dispatch: any) {
        let x = values.date;
        x.setHours(x.getHours() - x.getTimezoneOffset() / 60);
        let data = JSON.parse(JSON.stringify(values));
        data.date = x.toISOString();
        dispatch(forms.setFormProcessing());
        api.editAppointment(data).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Chästlizetteleintrag wurden erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(setSelectedObject({}));
            getAppointmentsOfGroupFromAPI(dispatch, values.groupId);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}