import React, {Component} from "react";
import {Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import * as sessionHelper from "../../helpers/sessionHelper";
import store from "../../store";
import * as formActions from "../../actions/formActions";

class Navigation extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            activeItem : ""
        }
    }

    static changeNavigation() : void {
        store.dispatch(formActions.clearErrorMessage());
        store.dispatch(formActions.clearSuccessMessage());
    }

    handleMenuItemClick = (e: any, {name}: any) => this.setState({activeItem: name});

    render(): React.ReactNode {
        const {activeItem} = this.state;

        return (
            <div className="main-navigation" onClick={Navigation.changeNavigation}>
                <Menu stackable>
                    <Menu.Item as={ Link } to="/" name='logo' onClick={this.handleMenuItemClick}>
                        <img src="../logo.png" alt="TourLive Logo"/>
                    </Menu.Item>
                    {sessionHelper.LoggedIn() &&
                        <>
                            <Menu.Item as={ Link } key="nav-home" to="/" name='home' active={activeItem === 'home'} onClick={this.handleMenuItemClick}>
                                Startseite
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.NotLoggedIn() &&
                        <>
                            <Menu.Item as={ Link } key="nav-login" to="/login" name='login' active={activeItem === 'login'} onClick={this.handleMenuItemClick}>
                                Login
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.HasScoutingAccess() &&
                        <>
                            <Menu.Item as={ Link } key="nav-appointments" to="/appointments" name='appointments' active={activeItem === 'appointments'} onClick={this.handleMenuItemClick}>
                                Chästlizettel
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.HasScoutingAccess() &&
                    <>
                        <Menu.Item as={ Link } key="nav-reservations" to="/kool" name='reservations' active={activeItem === 'reservations'} onClick={this.handleMenuItemClick}>
                            Reservationen
                        </Menu.Item>
                    </>
                    }
                    {sessionHelper.HasScoutingAccess() &&
                        <>
                            <Menu.Item as={ Link } key="nav-expenses" to="/expenses" name='expenses' active={activeItem === 'kool'} onClick={this.handleMenuItemClick}>
                                Spesen
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.IsAdmin() &&
                        <>
                            <Menu.Item as={ Link } key="nav-groups" to="/groups" name='groups' active={activeItem === 'groups'} onClick={this.handleMenuItemClick}>
                                Gruppen
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-contacts" to="/contacts" name='contacts' active={activeItem === 'contacts'} onClick={this.handleMenuItemClick}>
                                Kontakte
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-users" to="/users" name='users' active={activeItem === 'users'} onClick={this.handleMenuItemClick}>
                                Benutzer
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.LoggedIn() &&
                        <>
                            <Menu.Item as={ Link } key="nav-about" to="/about" name='about' active={activeItem === 'about'} onClick={this.handleMenuItemClick}>
                                Impressum
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-logout" to="/logout" name='logout' active={activeItem === 'logout'} onClick={this.handleMenuItemClick}>
                                Logout
                            </Menu.Item>
                        </>
                    }
                </Menu>
            </div>
        );
    }
}

export default Navigation;