import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import {CreateExpenseFormValues} from "../interfaces/expenseInterface";

function receiveExpenses(data : any) {
    return {
        type : types.SET_EXPENSES,
        data : data
    }
}

function receiveKoolError(errorMessage : string) {
    return {
        type : types.SET_EXPENSES_ERROR,
        data : errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_EXPENSES_CURRENT_STATE,
        data : state
    }
}

function changeFilteringOfData(data : any) {
    return {
        type : types.SET_EXPENSES_FILTER,
        data : data
    }
}

function setSelectedObject(koolEvent : object) {
    return {
        type : types.SET_EXPENSES_SELECTED_OBJECT,
        data : koolEvent
    }
}

export function addExpenseForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(setSelectedObject({}));
        //dispatch(receiveExpenses([]));
        dispatch(changeState("success"));
    }
}

export function onFilterChanged(filter : any): any {
    return function (dispatch : any) {
        dispatch(changeFilteringOfData(filter));
    }
}

function getExpensesFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getExpenses().then(function (res) {
        dispatch(receiveExpenses(res));
    }).catch(function (error) {
        dispatch(receiveKoolError(error));
    });
}

export function getExpenses() : any {
    return function (dispatch : any) {
        getExpensesFromAPI(dispatch);
    }
}

export function addExpense(values : CreateExpenseFormValues): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        const formData = new FormData();
        formData.append('file', values.file as Blob, values.file?.name);
        formData.append('values', JSON.stringify(values));
        api.addExpense(formData).then(function (res : any) {
            dispatch(forms.setFormSuccess("Deine Spesen wurde erfolgreich an den Finanzer übermittelt."));
            dispatch(changeState("success"));
            dispatch(setSelectedObject({}));
            //getExpensesFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}