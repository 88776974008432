import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";

function receiveContacts(data : any) {
    return {
        type : types.SET_CONTACTS,
        data : data
    }
}

function receiveContactsError(errorMessage : string) {
    return {
        type: types.SET_CONTACTS_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_CONTACTS_CURRENT_STATE,
        data : state
    }
}

function setSelectedObject(contact : object) {
    return {
        type : types.SET_CONTACTS_SELECTED_OBJECT,
        data : contact
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_CONTACTS_SELECTED_OBJECT,
        data : {}
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getContactAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getContactFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addContactForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getContactsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getContacts().then(function (res) {
        dispatch(receiveContacts(res));
    }).catch(function (error) {
        dispatch(receiveContactsError(error));
    });
}

function getContactFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getContact(id).then(function (res) {
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveContactsError(error));
            reject();
        });
    });
}

export function getContacts() : any {
    return function (dispatch : any) {
        getContactsFromAPI(dispatch);
    }
}

export function deleteContact(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteContact(id).then(function (res) {
            dispatch(forms.setFormSuccess("Der Kontakt wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getContactsFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addContact(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addContact(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Der Kontakt wurde erfolgreich abgespeichert."));
            dispatch(changeState("success"));
            getContactsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editContact(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editContact(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Kontakt wurde erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getContactsFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}