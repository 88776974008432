import {combineReducers} from "redux";
import contactReducer from "./contactReducer";
import eventReducer from "./eventReducer";
import appointmentReducer from "./appointmentReducer";
import groupReducer from "./groupReducer";
import formReducer from "./formReducer";
import userReducer from "./userReducer";
import currentUserReducer from "./currentUserReducer";
import koolReducer from "./koolReducer";
import expenseReducer from "./expenseReducer";

const rootReducer = combineReducers({
    contacts: contactReducer as any,
    events : eventReducer as any,
    appointments : appointmentReducer as any,
    groups : groupReducer as any,
    users : userReducer as any,
    form : formReducer as any,
    currentUser : currentUserReducer as any,
    kool : koolReducer as any,
    expenses: expenseReducer as any
});

export default rootReducer;