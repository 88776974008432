import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as koolActions from "../../actions/koolActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";
import {CreateReservationFormValues} from "../../interfaces/koolInterface";
import {DateTimePicker} from "react-widgets";


class AddReservation extends Component<IFormProps, CreateReservationFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            end: "", firstName: "", lastName: "", mail: "", phone: "", rooms: "", start: "", title: "", date: new Date()
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        firstName : Yup.string().required("* Feld ist erforderlich"),
        lastName : Yup.string().required("* Feld ist erforderlich"),
        mail : Yup.string().required("* Feld ist erforderlich").email("Es muss eine valide Mailadresse sein."),
        title : Yup.string().required("* Feld ist erforderlich"),
        start : Yup.string().required("* Feld ist erforderlich"),
        end : Yup.string().required("* Feld ist erforderlich"),
        rooms : Yup.string().required("* Feld ist erforderlich"),
        phone : Yup.string(),
        date : Yup.date().nullable(true)
    });

    onCancel(): void {
        store.dispatch(koolActions.getEvents());
        store.dispatch(koolActions.backToList());
    }

    handleSubmit(values : CreateReservationFormValues, actions : FormikHelpers<CreateReservationFormValues>) : void {
        store.dispatch(koolActions.addReservation(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Reservation hinzufügen"/>
                <h2>Reservation hinzufügen</h2>
                <p>Bitte überprüfe vor der Reservation in der Übersicht, ob der Raum überhaupt frei ist. Du wirst innerhalb von ca. 1 Tag eine Bestätigung vom Aktuar erhalten (Wochenende und Feiertage ausgenommen)</p>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="firstName">Vorname</label>
                                <Field type="text" name="firstName" placeholder="Hans"/>
                                <FormError name="firstName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="lastName">Nachname</label>
                                <Field type="text" name="lastName" placeholder="Muster"/>
                                <FormError name="lastName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="phone">Telefonnummer</label>
                                <Field type="text" name="phone" placeholder="012 345 56 78"/>
                                <FormError name="phone" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mail">Mail</label>
                                <Field type="text" name="mail" placeholder="mail@test.ch"/>
                                <FormError name="mail" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="title">Titel</label>
                                <Field type="text" name="title" placeholder="Cevi-Höck Progi 05.03.2022"/>
                                <FormError name="title" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="date">Datum</label>
                                <Field>
                                    {(bag : any) => (
                                        <DateTimePicker containerClassName="datetimepicker" value={bag.form.values.date} format={'DD.MM.YYYY'} time={false} onChange={value  => bag.form.setFieldValue("date", value)} />
                                    )}
                                </Field>
                                <FormError name="date" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="start">Startzeit</label>
                                <Field type="text" name="start" placeholder="19.00 Uhr"/>
                                <FormError name="start" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="end">Endzeit</label>
                                <Field type="text" name="end" placeholder="21.00 Uhr"/>
                                <FormError name="end" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="rooms">Räume (wenn mehrere mit Komma getrennt)</label>
                                <Field type="text" name="rooms" placeholder="Bosshardzimmer"/>
                                <FormError name="rooms" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Reservation hinzufügen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddReservation;