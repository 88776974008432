import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import sha256 from 'crypto-js/sha256';

function receiveUsers(data : any) {
    return {
        type : types.SET_USERS,
        data : data
    }
}

function receiveUsersError(errorMessage : string) {
    return {
        type: types.SET_USERS_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_USERS_CURRENT_STATE,
        data : state
    }
}

function setSelectedObject(user : object) {
    return {
        type : types.SET_USERS_SELECTED_OBJECT,
        data : user
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_USERS_SELECTED_OBJECT,
        data : {}
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getUserAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getUserFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addUserForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getUsersFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getUsers().then(function (res) {
        dispatch(receiveUsers(res));
    }).catch(function (error) {
        dispatch(receiveUsersError(error));
    });
}

function getUserFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getUser(id).then(function (res) {
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveUsersError(error));
            reject();
        });
    });
}

export function getUsers() : any {
    return function (dispatch : any) {
        getUsersFromAPI(dispatch);
    }
}

export function deleteUser(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteUser(id).then(function (res) {
            dispatch(forms.setFormSuccess("Der Benutzer wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getUsersFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addUser(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        let user = JSON.parse(JSON.stringify(values));
        user.password = sha256(values.password).toString();
        api.addUser(user).then(function (res : any) {
            dispatch(forms.setFormSuccess("Der Benutzer wurde erfolgreich abgespeichert."));
            dispatch(changeState("success"));
            getUsersFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editUser(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editUser(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Benutzer wurden erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getUsersFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}