import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as appointmentActions from "../../actions/appointmentActions";
import TitleHelper from "../shared/TitleHelper";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";

class DeleteAppointment extends Component<IFormProps, DeleteAppointmentFormValues> {
    constructor(props : any){
        super(props);
        this.state = {
            idAppointment: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }
    onCancel(): void {
        store.dispatch(appointmentActions.backToList());
    }

    handleSubmit(values : DeleteAppointmentFormValues, actions : FormikHelpers<DeleteAppointmentFormValues>) : void {
        store.dispatch(appointmentActions.deleteAppointment(values.idAppointment));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Chästlizetteleintrag löschen"/>
                <h2>Chästlizetteleintrag löschen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idAppointment">Chästlizetteleintrag ID</label>
                                <Field type="hidden" name="idAppointment"/>
                                <p>{this.state.idAppointment}</p>
                                <FormError name="idAppointment" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Chästlizetteleintrag löschen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default DeleteAppointment;