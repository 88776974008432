import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import NoMatch from "./NoMatch";
import Home from "../home/Home";
import GroupsContainer from "../../containers/GroupsContainer";
import ContactsContainer from "../../containers/ContactsContainer";
import AppointmentsContainer from "../../containers/AppointmentsContainer";
import About from "../about/About";
import UsersContainer from "../../containers/UsersContainer";
import {PrivateRoute} from "./PrivateRoute";
import LogoutContainer from "../../containers/LogoutContainer";
import LoginContainer from "../../containers/LoginContainer";
import KoolContainer from "../../containers/KoolContainer";
import ExpensesContainer from "../../containers/ExpensesContainer";

class Content extends Component {
    render(): React.ReactNode {
        return (
            <div className="main-content-wrapper">
                <div className="main-content">
                    <Switch>
                        <PrivateRoute path="/appointments" component={AppointmentsContainer}/>
                        <PrivateRoute path="/kool" component={KoolContainer}/>
                        <PrivateRoute path="/expenses" component={ExpensesContainer}/>
                        <PrivateRoute path="/contacts" component={ContactsContainer}/>
                        <PrivateRoute path="/users" component={UsersContainer}/>
                        <PrivateRoute path="/groups" component={GroupsContainer}/>
                        <PrivateRoute path="/home" component={Home}/>
                        <PrivateRoute exact path="/logout" component={LogoutContainer}/>
                        <Route exact path="/login" component={LoginContainer}/>
                        <PrivateRoute path="/about" component={About}/>
                        <PrivateRoute exact path="/" component={Home}/>
                        <Route path="*" component={NoMatch} status={404}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Content;