const HOST_API = process.env.REACT_APP_HOST_API;

export const EVENTS = HOST_API + "/Events";
export const CONTACTS = HOST_API + "/Contacts";
export const GROUPS = HOST_API + "/Groups";
export const APPOINTMENTS = HOST_API + "/Appointments";
export const USERS = HOST_API + "/Users";
export const HEARTBEAT = HOST_API + "/Heartbeat";
export const KOOL = HOST_API + "/Kool";
export const EXPENSES = HOST_API + "/Expenses";
