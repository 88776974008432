import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";

function receiveGroups(data : any) {
    return {
        type : types.SET_GROUPS,
        data : data
    }
}

function receiveGroupsError(errorMessage : string) {
    return {
        type : types.SET_GROUPS_ERROR,
        data : errorMessage
    }
}

function setSelectedObject(group : object) {
    return {
        type : types.SET_GROUPS_SELECTED_OBJECT,
        data : group
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_GROUPS_SELECTED_OBJECT,
        data : {}
    }
}

function changeState(state : string) {
    return {
        type : types.SET_GROUPS_CURRENT_STATE,
        data : state
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getGroupAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getGroupFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addGroupForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getGroupFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getGroup(id).then(function (res) {
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveGroupsError(error));
            reject();
        });
    });
}

function getGroupsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getGroups().then(function (res) {
        dispatch(receiveGroups(res));
    }).catch(function (error) {
        dispatch(receiveGroupsError(error));
    });
}

export function getGroups() : any {
    return function (dispatch : any) {
        getGroupsFromAPI(dispatch);
    }
}

export function deleteGroup(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteGroup(id).then(function (res) {
            dispatch(forms.setFormSuccess("Die Cevigruppe wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getGroupsFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addGroup(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addGroup(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Die Cevigruppe wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            getGroupsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editGroup(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editGroup(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen an der Cevigruppe wurden erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getGroupsFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}