import React, {Component} from "react";
import App from "./App";
import 'semantic-ui-css/semantic.min.css';
import '../styles/main.scss';

class Global extends Component<any, any> {
    render(): React.ReactNode {
        return <App/>
    }
}

export default Global;