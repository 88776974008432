export function getRoleList() : any {
    let array : any[] = [];
    array.push({value : 10, text : "Administrator"});
    array.push({value : 20, text : "Matchef"});
    array.push({value : 30, text : "Cevileiter"});
    array.push({value : 40, text : "Read Only"});
    return array;
}

export function getSections() : any {
    let array : any[] = [];
    array.push({value : "Verein allgemein", text : "Verein allgemein"});
    array.push({value : "Jungschar", text : "Jungschar"});
    array.push({value : "Fröschli", text : "Fröschli"});
    return array;
}

