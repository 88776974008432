import React, {Component, Fragment} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as contactActions from "../../actions/contactActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTable from "../shared/DataTable";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import EditContact from "./EditContact";
import DeleteContact from "./DeleteContact";
import AddContact from "./AddContact";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";

class Contacts extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.update = this.update.bind(this);
    }
    componentDidMount(): void {
        this.update();
    }

    update(): void {
        store.dispatch(contactActions.getContacts());
    }

    addGroup(): void {
        store.dispatch(contactActions.addContactForm());
    }

    deleteGroup(id : string) : void {
        store.dispatch(contactActions.getContactAndCallAction(id, "delete"));
    }

    editGroup(id : string) : void {
        store.dispatch(contactActions.getContactAndCallAction(id, "edit"));
    }

    renderActions(element : any) {
        return (
            <Fragment>
                <a href="#" onClick={() => this.deleteGroup(element.idContact)}><FontAwesomeIcon icon={['fas', 'trash']} /> Löschen</a>
                <a href="#" onClick={() => this.editGroup(element.idContact)}><FontAwesomeIcon icon={['fas', 'edit']} /> Bearbeiten</a>
            </Fragment>
        )
    }

    addActions(items : any) : void {
        items.map((element : any) => {
            element.action = this.renderActions(element);
        });
    }

    render() {
        const {store, contacts} = this.props;
        this.addActions(contacts);
        let headings = [
            {"name" : "ID", "sortkey" : "idContact"},
            {"name" : "Vorname", "sortkey" : "firstName"},
            {"name" : "Nachname", "sortkey" : "lastName"},
            {"name" : "Typ", "sortkey" : "contactType"},
            {"name" : "Aktionen", "sortkey" : "action"}
        ];
        return(
            <>
                <Header as="h1" className="mainHeader">Kontakte (App)</Header>
                {isEmpty(store.selectedObject) && store.state !== "add" &&
                    <div className="button-group">
                        <Button onClick={this.addGroup} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Kontakt hinzufügen</Button>
                        <Button onClick={this.update} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'sync']} />Liste neuladen</Button>
                    </div>
                }
                <FormMessageContainer/>
                {(() => {
                    switch (store.state) {
                        case "success":
                            return <DataTable headings={headings} rows={contacts} onSort={null} />;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        case "edit":
                            return <EditContact initalValues={store.selectedObject}/>;
                        case "delete":
                            return <DeleteContact initalValues={store.selectedObject}/>;
                        case "add":
                            return <AddContact />;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Contacts;