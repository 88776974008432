import {applyMiddleware, createStore} from "redux";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import reducers from "./reducers";
import {persistReducer, persistStore} from "redux-persist";
import promise from "redux-promise-middleware";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk"
import * as types from "../src/actions/actionTypes";
import history from "./global/history";

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['contacts', 'appointments', 'groups', 'users']
};

export const authMiddleware = ({ dispatch, getState } : any) => (next : any) => (action : any) => {
    if (action.type !== types.SET_LOGOUT) {
        let token = getState().currentUser.token;
        let tokenExpirationDate = getState().currentUser.tokenExpirationDate;
        if (tokenExpirationDate !== "" && Date.prototype.isPrototypeOf(tokenExpirationDate) && token !== ""){
            if (new Date(tokenExpirationDate) < new Date()) {
                action = {type: types.SET_LOGOUT, data: ""};
                history.push("/login");
            }
        }
    }
    return next(action);
}

const middleware = applyMiddleware(authMiddleware, promise, thunk);
// @ts-ignore
const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(pReducer, middleware);
export const persistor = persistStore(store);
export default store;

