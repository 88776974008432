import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as expenseActions from "../../actions/expenseActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";
import {CreateExpenseFormValues} from "../../interfaces/expenseInterface";
import SimpleSelectListForm from "../shared/forms/SimpleSelectListForm";
import {getSections} from "../../helpers/SelectListHelpers";
import SignatureCanvas from 'react-signature-canvas'


class AddExpense extends Component<IFormProps, CreateExpenseFormValues> {
    signature = {} as SignatureCanvas | null;
    file = null;
    constructor(props : any) {
        super(props);
        this.state = {
            amount: 0,
            description: "",
            firstName: "",
            iban: "",
            lastName: "",
            mail: "",
            nameOfBank: "",
            owner: "",
            place: "",
            street: "",
            section: "",
            signature: "",
            file: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        firstName : Yup.string().required("* Feld ist erforderlich"),
        lastName : Yup.string().required("* Feld ist erforderlich"),
        mail : Yup.string().required("* Feld ist erforderlich").email("Es muss eine valide Mailadresse sein."),
        street : Yup.string().required("* Feld ist erforderlich"),
        place : Yup.string().required("* Feld ist erforderlich"),
        nameOfBank : Yup.string().required("* Feld ist erforderlich"),
        iban : Yup.string().required("* Feld ist erforderlich"),
        owner : Yup.string(),
        amount: Yup.number().required("* Feld ist erforderlich"),
        section: Yup.string().required("* Feld ist erforderlich"),
        description : Yup.string().required("* Feld ist erforderlich"),
    });

    onCancel(): void {
        store.dispatch(expenseActions.getExpenses());
        store.dispatch(expenseActions.backToList());
        this.signature?.clear();
    }

    handleSubmit(values : CreateExpenseFormValues, actions : FormikHelpers<CreateExpenseFormValues>) : void {
        if (this.signature?.isEmpty() || this.file === null) return;
        values.file = this.file;
        // @ts-ignore
        values.signature = this.signature?.getTrimmedCanvas().toDataURL('image/png').toString();
        store.dispatch(expenseActions.addExpense(values));
    }

    handleFileUpload = (event: any) => {
        this.file = event.currentTarget.files[0];
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Spesen rückerstatten"/>
                <h2>Spesen rückerstatten</h2>
                <br/>
                <p>Mit diesem Formular kannst du ganz einfach die Spesen rückerstatten lassen. Fülle das Formular aus und lade einen Beleg noch.<br/>
                Im Anschluss wird dir einen Bestätigungsmail zugestellt.</p>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="firstName">Vorname *</label>
                                <Field type="text" name="firstName" placeholder="Hans"/>
                                <FormError name="firstName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="lastName">Nachname *</label>
                                <Field type="text" name="lastName" placeholder="Muster"/>
                                <FormError name="lastName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="street">Adresse *</label>
                                <Field type="text" name="street" placeholder="Mustergasse 10"/>
                                <FormError name="street" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="place">PLZ und Ort *</label>
                                <Field type="text" name="place" placeholder="8635 Dürnten"/>
                                <FormError name="place" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="mail">Mail *</label>
                                <Field type="text" name="mail" placeholder="mail@test.ch"/>
                                <FormError name="mail" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="nameOfBank">Name der Bank *</label>
                                <Field type="text" name="nameOfBank" placeholder="ZKB"/>
                                <FormError name="nameOfBank" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="iban">Konto-Nr. (IBAN-Nummer angeben) *</label>
                                <Field type="text" name="iban" placeholder="CH31 8123 9000 0012 4568 9"/>
                                <FormError name="iban" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="owner">Kontoinhaber (wenn nicht identisch mit Personalien)</label>
                                <Field type="text" name="owner" placeholder="Trudi Muster"/>
                                <FormError name="owner" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="section">Arbeitsgebiet *</label>
                                <SimpleSelectListForm options={getSections()} selectName="section" selectedValue={this.state.section} defaultText="-- Arbeitsgebiet auswählen --" />
                                <FormError name="section" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="amount">Betrag *</label>
                                <Field type="number" name="amount" placeholder="25.50"/>
                                <FormError name="amount" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="description">Kurze Beschreibung (z.B. Nachmittagsprogramm Gruppe Minions) *</label>
                                <Field type="text" name="description" placeholder="Nachmittagsprogramm Gruppe Minions"/>
                                <FormError name="description" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="file">Beleg *</label>
                                <input id="file" name="file" type="file" accept="image/*" onChange={this.handleFileUpload}/>
                                <FormError name="file" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="signature">Unterschrift *</label>
                                <SignatureCanvas penColor='black'
                                                 canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { this.signature = ref }} />
                                <FormError name="signature" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Spesenrückerstattung absenden</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddExpense;