import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import moment from "moment";

function receiveEvents(data : any) {
    return {
        type : types.SET_KOOL_EVENTS,
        data : data
    }
}

function receiveReservations(data : any) {
    return {
        type : types.SET_KOOL_RESERVATIONS,
        data : data
    }
}

function receiveKoolError(errorMessage : string) {
    return {
        type : types.SET_KOOL_ERROR,
        data : errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_KOOL_CURRENT_STATE,
        data : state
    }
}

function changeFilteringOfData(data : any) {
    return {
        type : types.SET_KOOL_FILTER,
        data : data
    }
}

function setSelectedObject(koolEvent : object) {
    return {
        type : types.SET_KOOL_SELECTED_OBJECT,
        data : koolEvent
    }
}

export function addReservationForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(setSelectedObject({}));
        dispatch(receiveEvents([]));
        dispatch(changeState("success"));
    }
}

export function onFilterChanged(filter : any): any {
    return function (dispatch : any) {
        dispatch(changeFilteringOfData(filter));
    }
}

function getEventsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getKoolEvents().then(function (res) {
        dispatch(receiveEvents(res));
    }).catch(function (error) {
        dispatch(receiveKoolError(error));
    });
}

export function getEvents() : any {
    return function (dispatch : any) {
        getEventsFromAPI(dispatch);
    }
}

function getReservationsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getKoolReservations().then(function (res) {
        dispatch(receiveReservations(res));
    }).catch(function (error) {
        dispatch(receiveKoolError(error));
    });
}

export function getReservations() : any {
    return function (dispatch : any) {
        getReservationsFromAPI(dispatch);
    }
}

export function addReservation(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        let data = JSON.parse(JSON.stringify(values));
        if (values.date === "" ||  values.date === " " || values.date === null || values.date === undefined) {
            data.date = "";
        } else {
            data.date = moment(values.date).format('YYYY-MM-DD');
        }
        api.addReservation(data).then(function (res : any) {
            dispatch(forms.setFormSuccess("Die Reservation wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            dispatch(setSelectedObject({}));
            getEventsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}