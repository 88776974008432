import React, {Component} from "react";
import {Header} from "semantic-ui-react";

class Home extends Component {
    render() {
        return(
            <>
                <Header as="h1" className="mainHeader">Home</Header>
                <div className="content">
                    <p>Lieber Leiter, mit dieser Anwendung kannst du ganz einfach den "Chästlizettel" für's nächste Programm eintragen und damit die Informationen so den Eltern weitergeben.</p>
                    <Header as="h3">Anleitung</Header>
                    <p>Halte dich einfach an folgende Schritte. Dann solte der Eintrag im Nuh gemacht sein ;-)</p>
                    <ul>
                        <li>Wähle den Menüpunkt "Chästlizettel" oben aus.</li>
                        <li>Im Dropdown, wählst du nun deine Gruppe aus. Es erscheinen automatisch die bereits eingetragenen "Zettel" (Nur jene, welche in der Zukunft liegen)</li>
                        <li>Button "Chästlizetteleintrag hinzufügen" anklicken. Es öffnet sich automatisch ein Formular.</li>
                        <li>Datum anpassen und restliche Angaben eintragen</li>
                        <li>Nach Abschluss den grünen Button "Chästlizetteleintrag hinzufügen" anklicken um die Angaben zu speichern.</li>
                        <li>Der Chästlizettel erscheint nun automatisch auf der Webseite und in der Mobileapp.</li>
                    </ul>
                    <Header as="h3">Fragen, Unklarheiten oder Ideen?</Header>
                    <p>Du weisst schon, wenn du fragen kannst. ;-)</p>
                    <Header as="h3">Idee</Header>
                    <p>Silja Nielsen v/o Tamina</p>
                </div>
            </>
        );
    }
}

export default Home;